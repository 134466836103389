@font-face {
  font-family: "CFB1 American Patriot";
  src: local("CFB1 American Patriot"), url("./assets/fonts/cfb1-american-patriot/CFB1 American Patriot Normal.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'CFB1 American Patriot', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
